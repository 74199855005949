.react-calendar {
  border: none;
}

.chakra-ui-dark .react-calendar__tile:disabled {
  background-color: #4a5568;
}

.react-calendar__tile--now {
  background-color: transparent;
  color: black;
}

.chakra-ui-dark .react-calendar__tile--now {
  color: white;
}

.chakra-ui-dark .react-calendar {
  background-color: #2d3748;
  color: white;
}

.chakra-ui-dark .react-calendar__tile:disabled {
  background-color: #4a5568;
}

.chakra-ui-dark .react-calendar__navigation button[disabled] {
  background-color: #4a5568;
}

.chakra-ui-dark .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #4a5568;
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
